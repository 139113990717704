// import { graphql } from 'gatsby'
import { PiL2023PageView } from '../../parade'

// export const query = graphql`
//   query paradeGroups1 {
//     paradeGroups1: allContentfulParadeGroup2019(
//       sort: { fields: [name], order: ASC }
//     ) {
//       edges {
//         node {
//           name
//           category
//           websiteUrl
//           twitterUrl
//           facebookUrl
//           instagramUrl
//           emailUrl
//         }
//       }
//     }
//   }
// `

export default PiL2023PageView
